(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbJsExtends"), require("mobxReact"), require("gsap"), require("sbDataLayer"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-sport-scoreboards", ["React", "mobx", "sbInternalMsgBus", "sbRespBlockLib", "sbJsExtends", "mobxReact", "gsap", "sbDataLayer"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-sport-scoreboards"] = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbJsExtends"), require("mobxReact"), require("gsap"), require("sbDataLayer"));
	else
		root["sb-responsive-sport-scoreboards"] = factory(root["React"], root["mobx"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["sbJsExtends"], root["mobxReact"], root["gsap"], root["sbDataLayer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__29__) {
return 